<template>
    <div class="aops-pull-new-activity-permission">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-activity-permission-wrap">
            <div class="aop-activity-title">活动权限</div>
            <div class="aop-search-form search-form">
                <el-form ref="form" :inline="true" :model="form" label-width="72px">
                    <el-form-item label="用户名称">
                        <el-input v-model="form.username" placeholder="请输入用户名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮箱">
                        <el-input v-model="form.email" placeholder="请输入用户邮箱" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="权限状态">
                        <el-select v-model="form.status">
                            <el-option
                                v-for="item in status"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="text-btn">
                    <span class="serach-button" @click="handleQuery">查询</span>
                    <span class="clear-button"  @click="resetQuery">清空</span>
                </div>
            </div>
            <el-table
                v-loading="isTableLoading"
                :data="list"
                style="width: 100%;"
                class="data-table aop-permission-table"
                header-cell-class-name="table-head"
                cell-class-name="table-cell">
                <el-table-column
                    v-for="col in tableColumn"
                    :key="col.prop"
                    :prop="col.prop"
                    :label="col.label"
                    :min-width="col.minWidth"
                >
                    <template slot-scope="{row}">
                        <!-- 状态 -->
                        <span v-if="col.prop === 'status'">
                            {{row.status | statusFilter}}
                        </span>
                        <div v-else-if="col.prop === 'operation'">
                            <el-button
                                size="mini"
                                type="text"
                                @click="handleOperation(row)"
                            >
                                <i v-if="`${row.status}` === '1'" class="iconfont">&#xe64b;</i>
                                <i v-else class="iconfont">&#xe64d;</i>
                                {{`${row.status}` === '1' ? '禁用': '启用'}}
                            </el-button>
                        </div>
                        <span v-else>{{row[col.prop] || '--'}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="aop-pagination-wrap">
                <el-pagination
                    :page-sizes="[10,20,50]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    :current-page.sync="currentPage"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/promotions';
import { tableColumn, initForm, status } from './activity_permission_data';

export default {
    filters: {
        /**
         * 状态格式化
         * @param {number} val 状态
         * @returns {string}
        */
        statusFilter(val) {
            const child = status.find((item) => item.value === `${val}`);
            return child ? child.text : val;
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            // 表格数据
            tableColumn,
            isTableLoading: false, // 表格loading
            list: [], // 表格数据
            pagesize: 10,
            total: 0,
            currentPage: 1,
            status,
            form: { ...initForm }
        };
    },
    /**
     * mounted
    */
    mounted() {
        this.getList();
    },
    methods: {
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/promotions/pull-new-management');
        },
        /**
         * 获取表格数据
        */
        getList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pagesize,
                page: this.currentPage,
                filter: {
                    ...this.form
                },
                sort: 'DESC',
                order_by: 'created_at'
            };
            api.activityPermissionList(data).then((res) => {
                if (res.data.code === 10200) {
                    this.list = res.data.data.items || [];
                    this.total = res.data.data.total || 0;
                } else {
                    this.$$error(res.data.message);
                }
                this.isTableLoading = false;
            }).catch(() => {
                this.isTableLoading = false;
                this.list = [];
            });
        },
        /**
         * 分页
         * @param {number} val 一页多少条
        */
        handleSizeChange(val) {
            this.pagesize = val;
            this.getList();
        },
        /**
         * 分页
         * @param {number} val 页码
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**
         * 查询
        */
        handleQuery() {
            this.currentPage = 1;
            this.getList();
        },
        /**
         * 重置
        */
        resetQuery() {
            this.form = { ...initForm };
            this.$refs.form.resetFields();
        },
        /**
         * 停用/ 启用
         * @param {Object} row 操作的用户
        */
        handleOperation(row) {
            const data = {
                customer_id: row.customer_id,
                status: `${row.status}` === '1' ? 2 : 1
            };
            api.setActivityPermission(data).then((res) => {
                if (res.data.code === 10200) {
                    this.getList();
                } else {
                    this.$$error(res.data.message);
                }
            }).catch((error) => {
                this.$$error(error);
            });
        }
    }
};
</script>
<style lang="less">
@import "./activity_permission.less";
</style>
