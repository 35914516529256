export const tableColumn = [{
    label: '用户名称',
    prop: 'username',
    minWidth: '120px'
}, {
    label: '用户邮箱',
    prop: 'email',
    minWidth: '120px'
}, {
    label: '权限状态',
    prop: 'status',
    minWidth: '120px'
}, {
    label: '操作',
    prop: 'operation',
    minWidth: '120px'
}];

export const initForm = {
    username: '',
    email: '',
    status: '' // 1 启用 2禁用
};
export const status = [{
    text: '全部',
    value: ''
}, {
    text: '启用中',
    value: '1'
}, {
    text: '已禁用',
    value: '2'
}];
